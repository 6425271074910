import AddOrEditLabelGuard from "./labels/AddOrEditLabelGuard"
import AddOrEditNomenclatureProviderGuard from "./providers/AddOrEditNomenclatureProviderGuard"
import DeleteProviderGuard from "./providers/DeleteProviderGuard"
import DeleteLabelGuard from "./labels/DeleteLabelGuard"
import LabelDetailsSheet from "../../../../sections/nomenclature/labels/LabelDetailsSheet"
import CustomProvidersDetailsSheet from "../../../../sections/nomenclature/providers/CustomProvidersDetailsSheet"
import AddOrEditSectionGuard from "./sections/AddOrEditSectionGuard"
import DeleteSectionGuard from "./sections/DeleteSectionGuard"
import SectionDetailsSheet from "../../../../sections/nomenclature/sections/SectionDetailsSheet"
import AddOrEditInvoicingConfigurationGuard from "./invoicing-configuration/AddOrEditInvoicingConfigurationGuard"
import DeleteInvoicingConfigurationGuard from "./invoicing-configuration/DeleteInvoicingConfigurationGuard"
import InvoicingConfigurationDetailsSheet from "../../../../sections/nomenclature/invoicing-configuration/InvoicingConfigurationDetailsSheet"
import DeleteC168Address from "./c168/DeleteC168Address"
import AddC168Address from "./c168/AddC168Address"
import C168AddressDetailsSheet from "../../../../sections/nomenclature/c168/C168AddressDetailsSheet"
import AddOrEditBankAccountGuard from "./bankAccounts/AddOrEditBankAccountGuard"
import DeleteBankAccountGuard from "./bankAccounts/DeleteBankAccountGuard"
import BankAccountDetailsSheet from "../../../../sections/nomenclature/bankAccounts/BankAccountDetailsSheet"
import DeletePropertyFromBankAccount from "./bankAccounts/DeletePropertyFromBankAccount"
import PropertiesFromBankAccountList from "../../../../sections/nomenclature/bankAccounts/MobilePropertiesFromBankAccountList"
import React from "react"
import UploadBankStatement from "../../../../sections/bank-statements/UploadBankStatement"

export default function NomenclatureGuard() {
    return (
        <>
            <AddOrEditLabelGuard />
            <DeleteLabelGuard />
            <LabelDetailsSheet />

            <AddOrEditNomenclatureProviderGuard />
            <DeleteProviderGuard />
            <CustomProvidersDetailsSheet />

            <AddOrEditSectionGuard />
            <DeleteSectionGuard />
            <SectionDetailsSheet />

            <AddOrEditInvoicingConfigurationGuard />
            <DeleteInvoicingConfigurationGuard />
            <InvoicingConfigurationDetailsSheet />

            <AddC168Address />
            <DeleteC168Address />
            <C168AddressDetailsSheet />

            <AddOrEditBankAccountGuard />
            <DeleteBankAccountGuard />
            <BankAccountDetailsSheet />
            <DeletePropertyFromBankAccount />
            <PropertiesFromBankAccountList />
            <UploadBankStatement />
        </>
    )
}
