import { ComplexQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import { CustomFile } from "rentzz"
import * as jsonpatch from "fast-json-patch"

export interface AddBankStatementRequest {
    bankAccountId: number
    files?: CustomFile[]
}

export interface AssociateExpenseToStatementTransactionRequest {
    currencyId: number
    propertyExpenseId: number
    amount: number
}

export const useAddBankStatementMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddBankStatementRequest }) => Api.addBankStatement(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.BankStatements])
            }
        },
    })
}

export const useUpdateBankStatementTransactionMutation = (transactionId: number) => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ statementId, operations }: { statementId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateBankStatementTransaction({
                transactionId,
                statementId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.BankStatements])
            }
        },
        mutationKey: ["update-transaction", { transactionId }],
    })
}
